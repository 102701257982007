import React from 'react';
import { Box } from 'rebass';
import { useMobileCheckHook } from '@/hooks/celebration';
import { ReactComponent as Logo } from '@/components/app/celebrate-logo.svg';
import { ReactComponent as BlackLogo } from '@/components/app/celebrate-logo-black.svg';

function CelebrateLogo({ isUnderLink, isBlack }) {
  if (isBlack) return <BlackLogo />;

  const isFromMobile = useMobileCheckHook();
  return (
    <Box
      sx={
        isUnderLink
          ? {}
          : {
              zoom: isFromMobile ? '15%' : '25%',
              MozTransform: `scale(${isFromMobile ? 15 : 25}%)`,
              MozTransformOrigin: 'left',
            }
      }
    >
      <Logo />
    </Box>
  );
}

export default CelebrateLogo;
